<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :filters="filters">
      <div slot="content-buttons">
        <Button _key="btnProductCreate" title="Locação" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="productCreate" :clicked="create" />
      </div>
    </ViewTemplateWithTable>

    <Modal title="Tem certeza que deseja excluir?" :width="550" v-show="showModal('removeRent')">
      <Alert type="warning">
        <span>
          Atenção, ao excluir uma Locação alguns processos (se houver) serão executados, esta ação é irreversível...
        </span>
      </Alert>
      <div>
        <i class="fa-solid fa-circle-xmark rent-icon-close"></i><span class="title"> Fatura ficará com status de
          Cancelada</span>
      </div>
      <div>
        <i class="fa-solid fa-circle-xmark rent-icon-close"></i><span class="title"> Pagamentos vinculados serão
          excluídos</span>
      </div>
      <Confirmation :isModal="false" title="Deseja excluir?" type="danger" :confirmed="remove" />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";


import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "productListView",
  components: {
    ViewTemplateWithTable,
    Confirmation,
    Alert,
    Modal,
    Button,
  },
  data() {
    return {
      id: "",
      panel: {
        module: "Locação",
        title: "Locação",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/rental/rent/get-all",
        urlDeleteAllApi: "/api/v1/rental/rent/delete",
        showChecks: false,
        headerTable: [
          {
            title: "Número/Data",
            field: "numberAndDate",
            type: "text",
            iconSearch: true,
          },
          {
            field: "fullName",
            fieldSecond: "identification",
            title: "Nome",
            type: "link",
            routeName: "rentUpdate",
            styleBody: "max-width: 500px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
            iconSearch: true,
          },
          {
            field: "consultant",
            title: "Consultor",
            type: "text",
            styleBody: "max-width: 150px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Período(Início/Fim)",
            field: "tags",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleBody: "max-width: 300px;",

          },
          {
            field: "statusRentalName",
            title: "Status",
            type: "class",
            fieldComparison: "statusRentalName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-info",
                fieldComparison: "Orçamento",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Aprovada",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Reprovada",
              },
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "Finalizada",
              },
            ],
          },
          {
            field: "statusInvoiceName",
            title: "Faturado",
            type: "class",
            fieldComparison: "statusInvoiceName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-info",
                fieldComparison: "Não Fatura",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Faturado",
              },
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Não Faturado",
              },
              {
                classCss: "text-center badge badge-info",
                fieldComparison: "Parcial",
              },
            ],
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleBody: "width: 150px;",
            type: "currency",
          },
          {
            type: "button",
            typeButton: "default",
            button: {
              classIcon: "fa-solid fa-trash",
              type: "danger",
              size: "small",
              eventName: "removeRent",
            },
          },
        ],
      },
      filters: [
        {
          type: "multiOptions",
          title: "Consultor",
          field: "Consultant",
          url: "/api/v1/human-resources/collaborator/select-all",
          propsParams: { isConsultant: true },
          value: "",
        },
        {
          type: "date",
          title: "Data Cadastro",
          field: "RegisteredDate",
          value: "",
        },
        {
          type: "date",
          title: "Data Efetivação",
          field: "DateAccepted",
          value: "",
        },
        {
          type: "options",
          title: "Faturamento",
          field: "StatusInvoiceName",
          options: [
            { text: "Faturado", value: "Faturado" },
            { text: "Parcial", value: "Parcial" },
            { text: "Não Faturado", value: "Não Faturado" },
            { text: "Não Fatura", value: "Não Fatura" },
          ],
          value: [],
        },
        {
          type: "dateRange",
          title: "Período de Locação",
          field: "PeriodRent",
          value: "",
        },
        {
          type: "options",
          title: "Status",
          field: "StatusRentalName",
          options: [
            { text: "Orçamento", value: "Orçamento" },
            { text: "Aprovada", value: "Aprovada" },
            { text: "Reprovada", value: "Reprovada" },
          ],
          value: [],
        },
        {
          type: "decimal",
          title: "Valor",
          field: "TotalGeneral",
          valueStart: 0,
          valueEnd: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    ...mapActions("generic", ["deleteAllApi"]),
    create() {
      let self = this;
      setTimeout(function () {
        self.$router.push({
          name: "rentCreate",
          params: { type: 1 },
        });
        self.removeLoading(["btnProductCreate"]);
      }, 200);

    },
    remove() {
      let params = {
        url: this.templateList.urlDeleteAllApi,
        selected: [this.id],
      };

      this.deleteAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("removeRent");
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "removeRent") {
          this.id = event.data.id;
          this.openModal("removeRent");
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.badge-default {
  background-color: #eeeeee;
  font-size: 13px !important;
  border-radius: 50px !important;
}

.div-status {
  margin-left: 35px;
}

.rent-progress {
  color: #4bb4e2;
}

.rent-accepted {
  color: #38A169;
}

.rent-rejected {
  color: #FF5454;
}

.rent-icon-close {
  color: red;
}
</style>